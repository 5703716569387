import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layouts';

const IndexPage = props => {
    const { wordpressPage } = props.data;
    console.log(wordpressPage);
    return (
        <Layout>
            <div
                dangerouslySetInnerHTML={{
                    __html: wordpressPage.content,
                }}
            />
            >
        </Layout>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query {
        wordpressPage(slug: { eq: "home" }) {
            id
            title
            content
        }
    }
`;
